import React from 'react';
import cx from 'classnames';

import { Carousel } from 'react-responsive-carousel';

import { Icon } from '@/components/shared/Icon';
import TestimonialCard, {
  TestimonialCardProps
} from '@/components/TestimonialCard';
import { RoundButton } from '@/components/shared/Button';

import { useViewport, ViewportPayload } from '@/hooks/useViewport';

import styles from './styles.module.css';
import Typography from '../shared/Typography';

const cardWidth = 774;

type ControlsProps = {
  onPrev: () => void;
  onNext: () => void;
  className?: string;
};

const Controls: React.FC<ControlsProps> = ({ onNext, onPrev, className }) => {
  return (
    <div className={cx('flex gap-8', className)}>
      <RoundButton onClick={onPrev} aria-label="previous">
        <Icon icon="arrow-left" color="white" />
      </RoundButton>

      <RoundButton onClick={onNext} aria-label="next">
        <Icon icon="arrow-right" color="white" />
      </RoundButton>
    </div>
  );
};

export type TestimonialCarouselProps = {
  autoPlay?: boolean;
  className?: string;
  interval?: number;
  list: TestimonialCardProps['data'][];
};

type TestimonialCarouselClassProps = {
  viewport: ViewportPayload;
} & TestimonialCarouselProps;

type StateProps = {
  currentSlide: number;
  autoPlay: boolean;
  list: TestimonialCarouselProps['list'];
  width: number;
  percentage: number;
};

const TestimonialCarousel: React.FC<TestimonialCarouselProps> =
  propsComponent => {
    class ExternalControlledCarousel extends React.Component<
      TestimonialCarouselClassProps,
      StateProps
    > {
      constructor(props: TestimonialCarouselClassProps) {
        super(props);

        this.state = {
          currentSlide: 0,
          autoPlay: !!props.autoPlay,
          list: props.list,
          width: 0,
          percentage: 0
        };
      }

      componentDidMount() {
        const updateWindowDimensions = () => {
          const width = document.getElementById(
            'testimonialCarousel'
          )?.offsetWidth;

          if (width) {
            const percentage = Math.round(
              ((cardWidth * 1.0) / (width * 1.0)) * 100
            );
            this.setState({ width, percentage });
          }
        };

        updateWindowDimensions();

        // if (!webkit) {
        window.addEventListener('resize', updateWindowDimensions);

        return () =>
          window.removeEventListener('resize', updateWindowDimensions);
        // }
      }

      next = () => {
        this.setState(state => ({
          currentSlide: state.currentSlide + 1
        }));
      };

      prev = () => {
        this.setState(state => ({
          currentSlide: state.currentSlide - 1
        }));
      };

      changeAutoPlay = () => {
        this.setState(state => ({
          autoPlay: !state.autoPlay
        }));
      };

      updateCurrentSlide = (index: number) => {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
          this.setState({
            currentSlide: index
          });
        }
      };

      render() {
        return (
          <div
            id="testimonialCarousel"
            className={cx(
              'overflow-hidden pb-10',
              this.props.className,
              styles.container
            )}
          >
            <Typography
              type="h3"
              className="text-center pb-4 pt-8 pl-4 pr-4 lg:pb-14 lg:pt-20 lg:pl-0 lg:pr-0 font-serif"
              style={{
                fontSize: 32,
                fontFamily: 'Libre Baskerville'
              }}
            >
              From our (adorable) readers
            </Typography>
            <Carousel
              centerMode={true}
              infiniteLoop
              autoPlay={!!this.props.autoPlay}
              selectedItem={this.state.currentSlide}
              showStatus={false}
              onChange={this.updateCurrentSlide}
              interval={this.props.interval}
              centerSlidePercentage={
                this.props.viewport.isDesktop ? this.state.percentage : 100
              }
              renderIndicator={() => null}
              showIndicators={false}
              useKeyboardArrows
              showThumbs={false}
            >
              {this.props.list.map((data, idx) => {
                return (
                  <div key={idx + 1} className="lg:pl-27 lg:pr-27">
                    <TestimonialCard data={data} />
                  </div>
                );
              })}
            </Carousel>
            <div className="flex flex-col items-center">
              <Controls
                className={cx(
                  this.props.viewport.isDesktop ? 'gap-8' : undefined
                )}
                onPrev={this.prev}
                onNext={this.next}
              />
            </div>
          </div>
        );
      }
    }

    const viewport = useViewport();

    return (
      <ExternalControlledCarousel viewport={viewport} {...propsComponent} />
    );
  };

export default TestimonialCarousel;
