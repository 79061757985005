export type BookData = {
  keyName: string;
  title: string;
  backgroundCarousel: string;
  backgroundBookDetails: string;
  backgroundColorBookDetails?: string;
  descriptionCarousel: string;
  descriptionExploreOurStories: string;
  descriptionBookDetails: string;
  carouselOrientation: string;
  writtenBy: string;
  illustratedBy: string;
  numberOfPages: number;
  coverType: 'hard';
  finishType: 'matte';
};

const data: BookData[] = [
  {
    keyName: 'the-bear-hug',
    backgroundCarousel: '/images/general/haiti.jpg',
    backgroundBookDetails: '/images/general/haiti.jpg',
    backgroundColorBookDetails: '#00000099',
    title: 'The Bear Hug',
    descriptionCarousel:
      'A fun and playful story to show your child that kids from around the world are more alike than different!',
    descriptionExploreOurStories:
      'A fun and playful story to show your child that kids from around the world are more alike than different!',
    descriptionBookDetails:
      'The Bear Hug features your child going on an imaginative journey around the world, while never leaving their bedroom. Your child will be introduced to new friends and learn the different ways that they play in their home country! The hope of this beautifully illustrated book is to break down an "us versus them" mindset in a fun and playful way.',
    carouselOrientation: 'right',
    writtenBy: 'Erin Bruchik',
    illustratedBy: 'Christina Dill',
    numberOfPages: 46,
    coverType: 'hard',
    finishType: 'matte'
  },
  {
    keyName: 'the-legend-of-the-starfish',
    backgroundCarousel: '/images/book-details/the-legend-of-the-starfish.jpg',
    backgroundBookDetails:
      '/images/book-details/the-legend-of-the-starfish.jpg',
    title: 'The Legend of the Starfish',
    backgroundColorBookDetails: '#000000aa',
    descriptionCarousel:
      'A new take on a classic tale to show your child that a small act of kindness can create a big impact!',
    descriptionExploreOurStories:
      'A new take on a classic tale to show your child that a small act of kindness can create a big impact!',
    descriptionBookDetails:
      'The Legend of the Starfish is inspired by Loren Eisley\'s classic poem, "The Star Thrower", and features your child as the main character who makes a difference for the one starfish. In this beautifully illustrated story, your child will learn that their small act of kindness can start a big wave of change that goes far beyond what they could imagine.',
    carouselOrientation: 'right',
    writtenBy: 'Alison Stephen',
    illustratedBy: 'Alison Stephen',
    numberOfPages: 36,
    coverType: 'hard',
    finishType: 'matte'
  },
  {
    keyName: 'adventures-in-wonderland',
    backgroundCarousel: '/images/book-details/adventures-in-wonderland.png',
    backgroundBookDetails: '/images/book-details/adventures-in-wonderland.png',
    title: 'Adventures in Wonderland',
    backgroundColorBookDetails: '#000000aa',
    descriptionCarousel:
      'An adventure down the rabbit hole into whimsical Wonderland while surrounded with familiar faces!',
    descriptionExploreOurStories:
      'An adventure down the rabbit hole into whimsical Wonderland while surrounded with familiar faces!',
    descriptionBookDetails:
      "Inspired by Alice's Adventures in Wonderland by Lewis Carroll, your child will see themselves as the main character taking the journey down the rabbit hole into a whimsical world filled with imagination and fun! Your child will love going on this this adventure with the Mad Hatter, Cheshire Cat, Tweedledee & Tweedledum, and more.",
    carouselOrientation: 'right',
    writtenBy: 'Alison Stephen',
    illustratedBy: 'Alison Stephen',
    numberOfPages: 36,
    coverType: 'hard',
    finishType: 'matte'
  },
  {
    keyName: 'winnie-the-pooh',
    backgroundCarousel: '/images/book-details/winnie-the-pooh.png',
    backgroundBookDetails: '/images/book-details/winnie-the-pooh.png',
    title: 'Winnie the Pooh',
    backgroundColorBookDetails: '#000000aa',
    descriptionCarousel:
      'A journey through the Hundred Acre Wood with characters your child already knows and loves and a new friend, too!',
    descriptionExploreOurStories:
      'A journey through the Hundred Acre Wood with characters your child already knows and loves and a new friend, too!',
    descriptionBookDetails:
      'Inspired by the tales of Winnie the Pooh by A. A. Milne, this new story features your child adventuring through the Hundred Acre Wood with Pooh, Piglet, and Rabbit. The classic characters and your child learn about kindness as they welcome a new friend to the Hundred Acre Wood together.',
    carouselOrientation: 'right',
    writtenBy: 'Alison Stephen',
    illustratedBy: 'Alison Stephen',
    numberOfPages: 36,
    coverType: 'hard',
    finishType: 'matte'
  }
];

export default data;
