export type TestimonialData = {
  url: string;
  description: string;
  name: string;
  stars: number;
  age?: undefined;
};

const data = [
  {
    url: '/images/home/testimonials/01.png',
    description:
      'Keepsake Tales allows your child’s imagination to take full flight. Our daughter loves stories that take her on an adventure, and with Keepsake Tales she actually gets to see herself experiencing these adventurous moments throughout the story. She can’t wait for her next adventure!',
    name: 'Mia',
    stars: 5
  },
  {
    url: '/images/home/testimonials/02.png',
    description:
      'My daughter loved seeing her image in the actual story, it made for a more interactive journey and made story time at night a lot of fun! Very cool way to make reading more fun!',
    name: 'Jaelyn',
    stars: 5
  },
  {
    url: '/images/home/testimonials/03.png',
    description:
      "Zane has really enjoyed seeing his picture in the keepsake tales book!  It's incredible how much more interested he is in his custom book where he is the hero than any other book we own!  We must read it every single day!",
    name: 'Zane',
    stars: 5
  },
  {
    url: '/images/home/testimonials/04.png',
    description:
      'There’s nothing better than seeing your child full of wonder and amazement as they read a book. That is exactly what happens when my son reads his Keepsake Tales book. He gets so into the book because he’s literally in the book.',
    name: 'Valentino',
    stars: 5
  },
  {
    url: '/images/home/testimonials/05.png',
    description:
      "My grandkids' Keepsake Tale made them smile bigger than any other gift! They felt so surprised and special to see themselves as the main character of their stories. And they read their books again and again!",
    name: 'Aliyah, Eli and Darius',
    stars: 5
  },
  {
    url: '/images/home/testimonials/06.png',
    description:
      'Jackson was so surprised to see his name and picture on the cover of the book. Being the main character made him eager to read the story! This is such a precious gift for kids, and it’s a great way to encourage a love for reading!',
    name: 'Jackson',
    stars: 5
  },
  {
    url: '/images/home/testimonials/dalton.png',
    description:
      'Dalton slept with his book for weeks and kept saying he was “famous”. He is adopted and does not know the ethnicity of his father, so he’s never been able to really see himself in book or tv characters. Truly seeing himself in this book was the sweetest gift!',
    name: 'Dalton',
    stars: 5
  }
];

export default data;
