import React from 'react';
import cx from 'classnames';
import Image from 'next/image';

import Typography from '@/components/shared/Typography';
import { Icon } from '@/components/shared/Icon';
import { TestimonialData } from '../TestimonialCarousel/data';

export type TestimonialCardProps = {
  className?: string;
  data: TestimonialData;
};

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  className,
  data
}) => {
  return (
    <div
      className={cx(
        'flex flex-col m-auto rounded-3xl overflow-hidden bg-white shadow-card xl:flex-row w-78 h-508 xl:w-720 xl:h-320',
        className
      )}
    >
      <div
        className="overflow-hidden w-78 h-320 bottom-1/2"
        style={{
          width: 308,
          height: 320,
          backgroundImage: 'url("/images/home/testimonials/background.png")'
        }}
      >
        <Image
          className="w-auto h-auto"
          width={308}
          height={320}
          src={data.url}
          alt={data.name}
          layout="fixed"
        />
      </div>
      <div className="flex flex-col p-6 xl:py-11 xl:pr-16 xl:pl-8 xl:w-98">
        <Typography className="mb-3 text-left xl:mb-2" type="b2">
          {data.description}
        </Typography>
        <div className="flex">
          {Array.from({ length: data.stars }).map((_, idx) => (
            <Icon key={idx + 1} icon="star" color="yellow" className="mr-1" />
          ))}
        </div>

        <div className="mt-auto flex flex-col">
          <Typography className="uppercase text-left mt-3 xl:mt-3" type="b3b">
            {`${data.name}${data.age ? ` , ${data.age} years old` : ''}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
