import { processCharactersAssetsURLs } from '@/services/characterProcessor.service';
import {
  Cover as CoverType,
  Character,
  Position,
  Size
} from '@/services/models';
import { processText } from '@/services/textBox.service';
import React from 'react';

type Props = {
  bookName: string;
  page: CoverType;
  character: Character;
  side: 'left' | 'right';
  spreadPageSize: Size;
  showFlippedCorner?: boolean;
};

const Cover: React.FC<Props> = ({
  bookName,
  page,
  character,
  spreadPageSize,
  showFlippedCorner = false
}) => {
  const backgroundImage = page?.backgroundImage.replace(
    bookName,
    `preview-${bookName}`
  );

  const sections: {
    position: Position;
    size: Size;
    urls: string[];
  }[] = [];

  if (character) {
    page?.characters.forEach(section => {
      sections.push({
        position: section.position,
        size: section.size,
        urls: processCharactersAssetsURLs(character.currentSelection, section)
      });
    });
  }

  const name = character && character.name ? character.name : '';
  const gender = character && character.gender ? character.gender : 'boy';
  // const processedPage = processText(name, gender, page)

  return (
    <div
      style={{
        maxWidth: spreadPageSize.width,
        overflow: 'hidden',
        borderColor: 'rgb(177, 177, 177)',
        borderStyle: 'solid',
        borderWidth: 1
      }}
    >
      <div className="flex w-auto sm:w-auto">
        <div className="flex flex-row justify-between w-full">
          <div className="grid w-full">
            <div className="row-span-full col-start-1 self-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ flex: 1 }}
                viewBox={`0 0 ${page.previewSize.width} ${page.previewSize.height}`}
                className="w-full"
                preserveAspectRatio="none"
              >
                <image
                  href={backgroundImage}
                  x={-page.previewPosition.x}
                  y={-page.previewPosition.y}
                  height={page.size.height}
                  width={page.size.width}
                  onError={({ target }: any) => {
                    target.href.baseVal =
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                  }}
                />
              </svg>
            </div>
            {/* Process characters */}
            {sections.map((section, i) => {
              return (
                <div
                  key={`sectionImage-${bookName}-${i}`}
                  className="row-span-full col-start-1 self-center"
                >
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ flex: 1 }}
                    viewBox={`0 0 ${page.previewSize.width} ${page.previewSize.height}`}
                    className="w-full"
                    preserveAspectRatio="none"
                  >
                    {section.urls.map((url, j) => {
                      if (url.indexOf('none') === -1) {
                        return (
                          <image
                            key={`sectionImage-${i}-${j}`}
                            href={url}
                            x={section.position.x - page.previewPosition.x}
                            y={section.position.y - page.previewPosition.y}
                            height={section.size.height}
                            width={section.size.width}
                            onError={({ target }: any) => {
                              target.href.baseVal =
                                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </svg>
                </div>
              );
            })}
            {/* Process text boxes */}
            {page?.textBoxes.map((textBox, i) => {
              const horizontalAlignment =
                textBox.horizontalAlignment === 'center'
                  ? 'middle'
                  : textBox.horizontalAlignment === 'left' // eslint-disable-next-line indent
                  ? 'start' // eslint-disable-next-line indent
                  : textBox.horizontalAlignment === 'right' // eslint-disable-next-line indent
                  ? 'end' // eslint-disable-next-line indent
                  : undefined;

              const processedXAnchor =
                horizontalAlignment === 'start'
                  ? textBox.position?.x - page.previewPosition.x
                  : horizontalAlignment === 'end' // eslint-disable-next-line indent
                  ? textBox.position?.x +
                    textBox.size.width -
                    page.previewPosition.x // eslint-disable-next-line indent
                  : horizontalAlignment === 'middle' // eslint-disable-next-line indent
                  ? textBox.position?.x +
                    textBox.size.width / 2 -
                    page.previewPosition.x // eslint-disable-next-line indent
                  : 0;

              return (
                <div
                  key={`textBox-${bookName}-${i}`}
                  className="row-span-full col-start-1 self-center"
                >
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ flex: 1 }}
                    viewBox={`0 0 ${page.previewSize.width} ${page.previewSize.height}`}
                    preserveAspectRatio="none"
                  >
                    {textBox.content.map((line, j) => {
                      const processedLine = line.replace(
                        /{{name}}/g,
                        character?.name || 'Olivia'
                      );

                      return (
                        <text
                          key={`textBox-${bookName}-${i}-${j}`}
                          x={processedXAnchor}
                          y={
                            textBox.position.y +
                            textBox.verticalStep * j -
                            page.previewPosition.y +
                            textBox.fontSize
                          }
                          fill={textBox.fontColor}
                          style={{
                            fontSize: textBox.fontSize,
                            textAnchor: horizontalAlignment,
                            fontFamily: textBox.fontFamily,
                            color: textBox.fontColor,
                            fontStyle: textBox.fontStyle
                              ? textBox.fontStyle
                              : undefined,
                            fontWeight: textBox.fontWeight
                              ? textBox.fontWeight
                              : undefined
                          }}
                        >
                          {processedLine}
                        </text>
                      );
                    })}
                  </svg>
                </div>
              );
            })}
            {showFlippedCorner && (
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0
                }}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0
                  }}
                >
                  <path d="M0 0H40L0 40V0Z" fill="#6BBCFF" />
                  <path
                    d="M0 0H40L0 40V0Z"
                    fill="url(#paint0_linear)"
                    fillOpacity="0.2"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="20"
                      y1="20"
                      x2="7"
                      y2="5.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopOpacity="0.82" />
                      <stop offset="1" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0
                  }}
                >
                  <path d="M40 40L0 40L40 0L40 40Z" fill="#E8E8E8" />
                  <path
                    d="M40 40L0 40L40 0L40 40Z"
                    fill="url(#paint0_linear)"
                    fillOpacity="0.2"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="18"
                      y1="21.5"
                      x2="25.5"
                      y2="29"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop />
                      <stop offset="1" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cover;
